<template>
  <DxDataGrid
    ref="dataGrid"
    class="sxr-grid caption-left regular-text-color"
    :data-source="datasource"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="false"
    :remote-operations="true"
    :word-wrap-enabled="true"
    :column-auto-width="true"
    :allow-column-reordering="!isCurrentBreakPointXs"
    width="100%"
    height="100%"
  >
    <DxHeaderFilter
      :visible="false"
      :hide-select-all-on-search="false"
    />
    />
    <DxPaging :page-size="25" />
    <DxColumnChooser
      :enabled="!isCurrentBreakPointXs"
      mode="select"
    />
    <DxExport
      :enabled="false"
      :formats="['csv', 'xlsx']"
      :texts="{
        exportAll: 'Export to {0}'
      }"
    />
    <DxScrolling
      mode="virtual"
    />
    <DxStateStoring
      :enabled="true"
      type="localStorage"
      storage-key="inv_r_grid"
    />
    <DxSearchPanel
      :visible="true"
      :highlight-case-sensitive="true"
    />
    <DxToolbar>
      <DxItem
        location="before"
        name="searchPanel"
      />
      <DxItem
        name="columnChooserButton"
      />
      <DxItem
        name="exportButton"
      />
    </DxToolbar>
    <DxColumn
      data-field="id"
      width="320"
      caption="ID"
      :visible="false"
    />
    <DxColumn
      data-field="email"
      caption="Email"
    />
    <DxColumn
      data-field="ip"
      caption="IP"
    />
    <DxColumn
      data-field="status"
      caption="Status"
      cell-template="statusCellTemplate"
    />
    <DxColumn
      data-field="created_at"
      caption="Created At"
      sort-order="desc"
      data-type="datetime"
    />
    <DxColumn
      data-field="activated_at"
      caption="Activated At"
      data-type="datetime"
      :visible="false"
    />
    <DxColumn
      :show-in-column-chooser="false"
      css-class="fixed-column-cell"
      type="buttons"
      :fixed="true"
      fixed-position="right"
      alignment="center"
      width="70"
      cell-template="actionsCellTemplate"
    />
    <template #statusCellTemplate="{ data: cell }">
      <StatusCell :value="cell.data.status" />
    </template>
    <template #actionsCellTemplate="{ data: cell }">
      <DxDropDownButton
        :split-button="false"
        :focus-state-enabled="false"
        :show-arrow-icon="false"
        :use-select-mode="false"
        :items="actions(cell)"
        icon="overflow"
        width="34"
        @item-click="onActionClick"
      />
    </template>
  </DxDataGrid>
</template>

<script>
import {
  DxColumn,
  DxColumnChooser,
  DxDataGrid, DxExport,
  DxHeaderFilter,
  DxItem,
  DxPaging, DxScrolling, DxSearchPanel, DxStateStoring,
  DxToolbar,
} from 'devextreme-vue/data-grid'
import { DxDropDownButton } from 'devextreme-vue/drop-down-button'
import dxDataSource from '@/data/invitation-request.datasource'
import StatusCell from '@/views/admin/invitation-requests/components/StatusCell.vue'

export default {
  name: 'DataGrid',
  components: {
    DxColumn,
    DxDataGrid,
    DxPaging,
    DxHeaderFilter,
    DxColumnChooser,
    DxToolbar,
    DxItem,
    DxScrolling,
    DxStateStoring,
    DxExport,
    DxSearchPanel,
    StatusCell,
    DxDropDownButton,
  },
  data() {
    return {
      datasource: dxDataSource.getInstance(this.$store),
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
  },
  methods: {
    actions({ data }) {
      return [
        {
          id: data.id,
          text: 'Send Invitation',
          action: 'send-invitation',
          icon: 'email',
          disabled: data.status !== 'new',
        },
      ]
    },
    onActionClick({ itemData }) {
      switch (itemData.action) {
        case 'send-invitation':
          this.sendInvitation(itemData.id)
          break
        default:
      }
    },
    sendInvitation(id) {
      this.$store.dispatch('invitationRequests/approve', { invitation_request_id: id }).then(data => {
        this.$bvToast.toast(data, {
          title: 'Success',
          toaster: 'b-toaster-top-center',
          variant: 'success',
          solid: true,
          appendToast: false,
        })

        this.$refs.dataGrid.instance.refresh()
      }).catch(() => {
        this.$bvToast.toast('Send invitation is failed. Please try again', {
          title: 'Error',
          toaster: 'b-toaster-top-center',
          variant: 'danger',
          solid: true,
          appendToast: false,
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/common/grid.scss';
.dark-layout .dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #404656;
  background: #353d55;
}
</style>
<style lang="scss" scoped>

</style>
